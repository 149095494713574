
































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";

interface Item {
  id: string;
  amount: number;
  dateCreated: Date;
  items: {
    id: string;
    price: number;
    title: string;
  }[];
  name: string;
  status: null | string;
  statusMessage: string;
  uid: string;
  corpName: string;
  billType: "bireysel" | "kurumsal";
  paymentType: "creditcard" | "transfer";
}

interface DownloadItem {
  "Sipariş No": string;
  "Fatura Adı": string;
  "Fatura Türü": string;
  "Ödeme Türü": string;
  "Tutar (₺)": string;
  Durum: string;
  Tarih: string;
  "e-Posta": string;
  Telefon?: string;
  "TC Kimlik No"?: string;
  "Vergi No"?: string;
}

export default Vue.extend({
  components: {
    Confirm
  },

  metaInfo() {
    return {
      title: "Siparişler"
    };
  },

  data() {
    return {
      search: "",
      loading: false,
      headers: [
        {
          text: "Sipariş No",
          align: "start",
          filterable: true,
          value: "id"
        },
        { text: "Fatura Adı", value: "name" },
        { text: "Satın Alınan Eğitimler", value: "items" },
        // { text: "Ödeme Türü", value: "paymentType" },
        { text: "Tutar (₺)", value: "amount" },
        { text: "Durum", value: "status", width: "90px" },
        { text: "Tarih", value: "dateCreated", width: "200px" },
        { text: "", value: "actions", sortable: false, width: "50px" }
      ],

      items: [
        {
          id: "",
          uid: "",
          name: "",
          corpName: "",
          items: [
            {
              id: "",
              price: 0,
              title: ""
            }
          ],
          amount: 0,
          status: null,
          statusMessage: "",
          dateCreated: new Date(),
          billType: "bireysel",
          paymentType: "creditcard"
        }
      ] as Item[],

      downloadData: [] as DownloadItem[]
    };
  },

  methods: {
    capitalize(text: string) {
      const words = text.split(" ");

      return words
        .map(word => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
    },

    async getItems() {
      this.loading = true;

      try {
        // Get all courses
        const query = await firebase
          .firestore()
          .collection("/apps/kahev-akademi/orders")
          .orderBy("dateCreated", "desc")
          .get();

        this.items = [];
        query.forEach(doc => {
          if (doc.exists) {
            const order = {
              id: doc.id,
              uid: doc.data().uid,
              name: doc.data().name,
              corpName: doc.data().corpName,
              items: doc.data().items,
              amount: doc.data().amount,
              status: doc.data().status,
              statusMessage: doc.data().statusMessage,
              dateCreated: doc.data().dateCreated.toDate(),
              billType: doc.data().billType,
              paymentType: doc.data().paymentType
            } as Item;

            this.items.push(order);

            // İndirme verisini oluştur
            const downloadItem = {
              "Sipariş No": order.id,
              "Fatura Adı":
                order.billType == "bireysel" ? order.name : order.corpName,
              "Fatura Türü": this.capitalize(order.billType),
              "Ödeme Türü":
                order.paymentType == "creditcard"
                  ? "Kredi Kartı"
                  : "Para Transferi",
              "Tutar (₺)": order.amount.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }),
              Durum: order.statusMessage,
              Tarih: order.dateCreated.toLocaleString("tr-TR"),
              "e-Posta": doc.data().email,
              Telefon: doc.data().phone ? doc.data().phone : "",
              "TC Kimlik No": doc.data().tckn,
              "Vergi No": doc.data().taxNumber
            } as DownloadItem;

            this.downloadData.push(downloadItem);
          }
        });
      } catch (err) {
        this.$notify({
          type: "error",
          text: `Sipariş listesi alınamadı.`
        });
        throw new Error(`Sipari listesi alınamadı. ${err.message}`);
      } finally {
        this.loading = false;
      }
    },

    async deleteItem(item: Item) {
      const $confirm = this.$refs.confirm as InstanceType<typeof Confirm>;
      const confirmation = await $confirm.open(
        "Emin misiniz?",
        "Bu işlem geri alınamaz. Siparişi silmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (!confirmation) return;

      try {
        // Eğitimi sil
        await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/orders/${item.id}`)
          .delete();

        this.$notify({
          type: "success",
          title: "İşlem Tamam",
          text: "Sipariş başarıyla silindi."
        });

        await this.getItems();
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Sipariş silinemedi."
        });

        throw new Error(`Sipariş silinemedi. ${err.message}`);
      }
    }
  },

  async mounted() {
    await this.getItems();
  }
});
